import { faBirthdayCake, faFire, faGavel, faGraduationCap, faShip, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import styled from 'styled-components';
import frankImg from '../../assets/images/frank.jpg';
import stefanieImg from '../../assets/images/stefanie.jpg';

const About = () => (
  <AboutWrapper>
    <Flex p={3} justifyContent="center" flexWrap="wrap" alignItems="center">
      <Box p={3}>
        <Styledh1>Over advocaat Frank Reynaert</Styledh1>
        <p>Carrière in beeld gebracht.</p>
      </Box>
      <ImageWrapper p={3}>
        <ImgTagWrapper>
          <TeamImg alt="Frank Reynaert" src={frankImg} />
          <p>Frank Reynaert</p>
        </ImgTagWrapper>
        <ImgTagWrapper>
          <TeamImgSmall alt="Stefanie" src={stefanieImg} />
          <p>Stefanie Allaert</p>
        </ImgTagWrapper>
      </ImageWrapper>
    </Flex>
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="19 november 1959"
        iconStyle={{ background: '#d26426', color: 'white' }}
        icon={<FontAwesomeIcon icon={faBirthdayCake} />}
      >
        <h3 className="vertical-timeline-element-title">Frank Reynaert</h3>
        <h4 className="vertical-timeline-element-subtitle">Veurne</h4>
        <p>
          Frank REYNAERT werd geboren te Veurne op 19 november 1959 uit een familie van handelaars, zelfstandigen. Alle voorgaande
          generaties, voor zover bekend, woonden en waren afkomstig uit Veurne waarbij betovergrootvader reeds als smid
          beroepshalve actief was en waarbij onmiddellijk na de eerste wereldoorlog de overgrootvader van Frank REYNAERT
          kunstonderwijs volgde aan het Sint Lucas Instituut te Gent om zijn smeedwerk in getekende ontwerpen en aquarellen te
          vereeuwigen ; familiekunstwerken die tot op heden in het kantoor van Meester REYNAERT worden bewaard.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="1975"
        iconStyle={{ background: '#d26426', color: 'white' }}
        icon={<FontAwesomeIcon icon={faFire} />}
      >
        <h3 className="vertical-timeline-element-title">Officier in de brandweerdienst</h3>
        <h4 className="vertical-timeline-element-subtitle">Veurne</h4>
        <p>
          Enerzijds is er, gezien vader Willy REYNAERT officier-dienstchef was van de stedelijke gemengde brandweerdienst van de
          Stad Veurne, ook zijn zoon gedurende 18 jaar betrokken bij de publieke dienst om uiteindelijk als officier in de
          brandweerdienst aldaar zijn activiteiten te beëindigen omstreeks 1993. Aan deze timelaps heeft Meester REYNAERT, met
          zijn diverse specialisaties in de brandweer (pomptechnieker, duiker, ...), uiteindelijk de door het Ministerie van
          Binnenlandse Zaken georganiseerde opleiding en derhalve het diploma &quot;technicus brandvoorkoming&quot; behaald. Dit
          laatste en alle technische opleidingen in het kader van de preferentiële activiteiten als advocaat zijn bijzonder nuttig
          gebleken.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="1984"
        iconStyle={{ background: '#d26426', color: 'white' }}
        icon={<FontAwesomeIcon icon={faGraduationCap} />}
      >
        <h3 className="vertical-timeline-element-title">Opleiding</h3>
        <h4 className="vertical-timeline-element-subtitle">Brussel</h4>
        <p>
          Van kunstsmeden tot fabrikanten en handelaars van kachels evolueerde de familietraditie om uiteindelijk Meester REYNAERT
          terecht te laten komen in de juridische sector. Na een humaniora in het reactionair Bisschoppelijk College der
          Onbevlekte Ontvangenis te Veurne stuurde pater familias REYNAERT zijn zoon naar de vrijzinnige Vrije Universiteit te
          Brussel alwaar uiteindelijk in juli 1984 hij zijn licentie in de rechtsgeleerdheid heeft behaald. Een bijzonder
          interessante filosofische confrontatie.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="1985"
        iconStyle={{ background: '#d26426', color: 'white' }}
        icon={<FontAwesomeIcon icon={faShip} />}
      >
        <h3 className="vertical-timeline-element-title">Marine</h3>
        <h4 className="vertical-timeline-element-subtitle">Op zee</h4>
        <p>
          Gezien de toenmalig bestaande verplichte legerdienst heeft Meester REYNAERT zijn vaderlandse plichten volbracht vanaf
          september 1985 bij de Belgische marine als reserve dek-officier. Na de afronding van zijn legerdienst behoorde hij
          continu tot de actieve reserve van de Marine component van Defensie. Als wachtofficier op de brug heeft hij de eer had
          te mogen dienen aan boord van diverse types marineschepen (MSI, MSC, MHSO, CMT, BSL) met als meest actieve inzet deze
          aan boord van de A 960 GODETIA waar hij jaren lang, en diverse kruisvaarten, deel van heeft uitgemaakt in het kader van
          de opleiding van de studenten van de hogere zeevaartschool te Antwerpen. Hij werd o.a. vereremerkt met het Kruis van
          Officier in de Kroonorde (KB 08.04.2011) en tevens met de herinneringsmedaille buitenlandse opdrachten of operaties, met
          het staafje ALLIED HARVEST. De militaire carrière werd afgerond met deelname op het hoofdkwartier van EUNAVFOR in het
          hoofdkwartier te Northwood (Londen) in de bestrijding van de piraterij aan de kusten van Somalië en aan de toegangen tot
          de Rode Zee. Afscheid werd genomen in de graad van korvetkapitein (R).
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="1988"
        iconStyle={{ background: '#d26426', color: 'white' }}
        icon={<FontAwesomeIcon icon={faUsers} />}
      >
        <h3 className="vertical-timeline-element-title">ADVOCATENASSOCIATIE LAGROU, VERSCHELDEN, CLEMENT &amp; REYNAERT</h3>
        <h4 className="vertical-timeline-element-subtitle">Veurne</h4>
        <p>
          Na de stageverplichtingen als advocaat heeft Meester REYNAERT gedurende meer dan 30 jaar deel uitgemaakt van de C.V.
          ADVOCATENASSOCIATIE LAGROU, VERSCHELDEN, CLEMENT &amp; REYNAERT met zetel te Veurne alwaar hij als vennoot in hoofdzaak
          zijn voorkeurmateries heeft behandeld, te weten, bouw-, mede-eigendoms- en goederenrecht.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement>
        <p>
          Naast de juridische vorming zijn twee eerder technische semi-professionele activiteiten mede bepalend geweest in het
          leven en kennisopleiding van Frank REYNAERT.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="2018"
        iconStyle={{ background: '#d26426', color: 'white' }}
        icon={<FontAwesomeIcon icon={faGavel} />}
      >
        <h3 className="vertical-timeline-element-title">REYNAERT ADVOCATEN</h3>
        <h4 className="vertical-timeline-element-subtitle">Koksijde</h4>
        <p>
          Sedert 01.01.2018 werd huidig kantoor REYNAERT ADVOCATEN opgestart onder de leiding van Meester REYNAERT waar met
          medewerkers een individueel kantoor wordt opgebouwd dat in hoofdzaak de voormelde voorkeurmateries behandelt.
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </AboutWrapper>
);

export default About;

const ImgTagWrapper = styled.div`
  text-align: left;
  > p {
    opacity: 0.7;
    margin-top: -5px;
  }
`;

const TeamImg = styled.img`
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12), 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  max-height: 300px;
  margin-right: 25px;

  @media (max-width: 400px) {
    max-width: 140px;
  }
`;
const TeamImgSmall = styled.img`
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12), 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  max-height: 240px;

  @media (max-width: 400px) {
    max-width: 120px;
  }
`;

const Styledh1 = styled.h1`
  color: #d26426;
`;

const AboutWrapper = styled(Box)`
  max-width: 1128px;
  margin: 0 auto;
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    margin: 0 50px;
  }
  .vertical-timeline::before {
    background: #f5dbcd;
  }
`;

const ImageWrapper = styled(Box)`
  text-align: center;
  display: flex;
  align-items: baseline;

  @media (max-width: 400px) {
    max-width: 320px;
  }
`;
