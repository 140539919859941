import React from 'react';
import GlobalHead from '../config/GlobalHead';
import GlobalStyle from '../config/GlobalStyle';
import { Provider } from '../contexts/context';
import About from '../modules/about/About';
import Footer from '../modules/footer/Footer';
import Header from '../modules/navigation/Header';

const Over = () => (
  <Provider>
    <GlobalStyle />
    <GlobalHead />
    <Header />
    <About />
    <Footer />
  </Provider>
);

export default Over;
